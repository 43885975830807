import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { navigate } from '@reach/router';
import axios from 'axios';
import HeaderWithInfo from '../Components/Common/HeaderWithInfo';
import Loader from '../Components/Loader';
import AccountSearch from '../Components/Account/Search';
import AccountResult from '../Components/Account/Results';
import AccountScoreCard from '../Components/Account/AccountScoreCard';
import AccountSpendCard from '../Components/Account/AccountSpendCard';
import CreateAccountRequest from '../Components/Admin/Requests/CreateAccount';
import Alert from '../Components/Common/Alert';
import { Context } from '../Context/RequestsContext';
import { Context as MenuContext } from '../Context/MenuContext';
import { Context as PageContext } from '../Context/PageContext';
import EditAccountRequest from '../Components/Admin/Requests/EditAccount';
import { saveUserPageVisitHistory } from '../store/actions/peopleAction';
import NoRecordFound from '../Components/Common/NoRecordFound';

const initialState = {
  results: [],
  loaded: true,
  scrolling: false,
  matchCount: 0,
  showingTotal: 0,
  cloudUsageFrameworkScore: {},
  cloudUsageFrameworkV2Score: {},
  count: 0,
  index: 0,
  estimatedMonthCost: 0,
  previousMonthCost: 0,
  currentSearch: {
    environments: [],
    status: [],
  },
  errorMessage: '',
};

const Accounts = (props) => {
  const { user } = props;
  const [results, setResults] = useState([]);
  const [response, setResponse] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [scrolling, setScrolling] = useState(false);
  const [matchCount, setMatchCount] = useState(0);
  const [showingTotal, setShowingTotal] = useState(0);
  const [newCloudUsageFrameworkScore, setNewCloudUsageFrameworkScore] = useState(0);
  const [cloudUsageFrameworkV2Score, setCloudUsageFrameworkV2Score] = useState(0);
  const [count, setCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [estimatedMonthCost, setEstimatedMonthCost] = useState(0);
  const [previousMonthCost, setPreviousMonthCost] = useState(0);
  const [currentSearch, setCurrentSearch] = useState({
    environments: [],
    status: [],
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [showAccntEditModal, setShowAccntEditModal] = useState(false);
  const [id, setId] = useState(null);
  const [accNum, setAccNum] = useState(null);
  const [accName, setAccName] = useState('');

  // Get filter data from Request Context
  const [modelPopup, setModelPopup] = useContext(Context);
  const { showAccntReqModal } = modelPopup;
  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu, , setSelectedSubMenu] = useContext(MenuContext);
  const [pageContextState, setPageContextState] = useContext(PageContext);
  const { accountPage } = pageContextState;

  const dispatch = useDispatch();

  // Get user login details
  useEffect(() => {
    if (accountPage === '') {
      setPageContextState({
        ...pageContextState,
        accountPage: 'account',
      });
      const apiUrl = `/api/Auth/SaveUserPageVisit/${user.employeeId}/account`;
      dispatch(saveUserPageVisitHistory(apiUrl));
    }
  }, [accountPage]);

  // Change menu css with peope page is loaded.
  useEffect(() => {
    setSelectedMenu('AWS');
    setSelectedSubMenu('accountSearch');
  }, []);
  const doSearch = (clear, pgIndex) => {
    const {
      searchType, criteria, environments, status,
    } = currentSearch;

    if (clear) { setLoaded(false); }
    // search only if criteria or environments and status is selected
    if (criteria !== undefined) {
      axios
        .get(
          `/api/account/search?t=${searchType}&q=${criteria}&e=${environments}&s=${status}&i=${clear ? 0 : pgIndex}`,
        )
        .then((response) => {
          const result = response.data;
          setResults(clear ? result.items : results.concat(result.items || []));
          setMatchCount(result.matchCount);
          setShowingTotal(result.showingTotal);
          setNewCloudUsageFrameworkScore(result.newCloudUsageFrameworkScore);
          setCloudUsageFrameworkV2Score(result.cloudUsageFrameworkV2Score);
          setCount(result.count);
          setPageIndex(result.index);
          setEstimatedMonthCost(result.estimatedMonthCost);
          setPreviousMonthCost(result.previousMonthCost);
          setLoaded(true);
          setScrolling(false);
          setResponse(true);
        })
        .catch((err) => {
          if (err.name !== 'AbortError') {
            setErrorMessage(err.message);
          }
          setResponse(true);
        });
    }
  };
  useEffect(() => {
    doSearch(false, pageIndex);
  }, [currentSearch, pageIndex]);

  const parseQueryString = () => {
    const { location } = props;
    const { search } = location;
    return queryString.parse(search);
  };

  const nextPage = () => {
    if (scrolling !== true && matchCount > showingTotal) {
      setScrolling(true);
      setPageIndex(pageIndex + 1);
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (Math.round(scrollTop) + clientHeight === scrollHeight) {
      nextPage();
    }
  };

  const executeSearch = (searchType, criteria, status, environments) => {
    if (
      currentSearch.searchType !== searchType
      || currentSearch.criteria !== criteria
      || currentSearch.status.length !== status.length
      || !currentSearch.status.every((e) => status.includes(e))
      || currentSearch.environments.length !== environments.length
      || !currentSearch.environments.every((e) => environments.includes(e))
    ) {
      setResults([]);
      setLoaded(false);
      setPageIndex(0);
      setCurrentSearch({
        searchType,
        criteria,
        status,
        environments,
      });
      const stateVar = {
        count,
        currentSearch: {
          searchType,
          criteria,
          status,
          environments,
        },
        loaded: false,
        pageIndex: 0,
        results: [],
        scrolling,
        showingTotal,
      };
      navigate(
        `/accounts?t=${searchType}&q=${criteria}&e=${environments}&s=${status}`,
        { state: stateVar, replace: true },
      );
    }
  };

  const clearSearch = (status, environments) => {
    setResponse(false);
    const defaultState = initialState;
    const currentSearchClr = {
      ...defaultState.currentSearch,
      status: status || [],
      environments: environments || [],
    };
    setResults([]);
    setLoaded(true);
    setScrolling(false);
    setMatchCount(0);
    setShowingTotal(0);
    setCount(0);
    setCurrentSearch(currentSearchClr);
    setErrorMessage('');
  };

  const onAccntReqModal = (newValue) => {
    setModelPopup({ ...modelPopup, showAccntReqModal: newValue });
  };

  const qs = parseQueryString();

  const beginEdit = (e, num, Anum, Aname) => {
    e.preventDefault();
    setShowAccntEditModal(true);
    setId(num);
    setAccName(Aname);
    setAccNum(Anum);
  };

  const cancelEdit = () => {
    setShowAccntEditModal(false);
    setId(null);
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          {/* <!-- Begin::Page Content --> */}
          <div className="card card-custom mb-4">
            <div className="card-header align-items-center">
              <div className="card-title align-items-center justify-content-between w-100">
                <HeaderWithInfo headerName="Accounts" />
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={onAccntReqModal}
                >
                  <span className="svg-icon svg-light">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      // eslint-disable-next-line react/no-unknown-property
                      t="1551322312294"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      height="1.2em"
                      width="1.2em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <defs />
                      <path d="M474 152m8 0l60 0q8 0 8 8l0 704q0 8-8 8l-60 0q-8 0-8-8l0-704q0-8 8-8Z" />
                      <path d="M168 474m8 0l672 0q8 0 8 8l0 60q0 8-8 8l-672 0q-8 0-8-8l0-60q0-8 8-8Z" />
                    </svg>
                  </span>
                  {' '}
                  Request an AWS Account
                </button>
              </div>
            </div>
            <div className="card-body accout-search">
              <div>
                <AccountSearch
                  selectedValue={qs.t}
                  search={qs.q}
                  environments={qs.e}
                  statuses={qs.s}
                  executeSearch={executeSearch}
                  clearSearch={clearSearch}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {errorMessage && (
          <div className="col-12">
            <Alert
              message={errorMessage}
              alertClass="light-danger"
              onClose={(val) => setErrorMessage(val)}
            />
          </div>
        )}
      </div>

      {/* <!-- Begin::Search list --> */}
      {loaded ? (
        <>
          {count > 0 ? (
            <>
              <div className="row mb-3">
                {/* <!-- Last Month Spend Card & Current Month Spend Estimate --> */}
                <AccountScoreCard
                  name="Cloud Usage Framework V2"
                  value={cloudUsageFrameworkV2Score}
                  showProg
                />
                <AccountScoreCard
                  name="Upcoming new rules for CUF"
                  value={newCloudUsageFrameworkScore}
                  showProg
                />

                {/* <!-- Last Month Spend Card & Current Month Spend Estimate --> */}
                <AccountSpendCard
                  name="Last Month Spend"
                  value={previousMonthCost}
                />
                <AccountSpendCard
                  name="Current Month Spend Estimate"
                  value={estimatedMonthCost}
                  status={previousMonthCost < estimatedMonthCost}
                />
              </div>
              <div className="row gutter-b">
                <div className="col-12">
                  <div>
                    <h6 className="text-inverse h6">
                      {matchCount > 0 ? (
                        <span>
                          {showingTotal}
                          {' '}
                          of
                          {' '}
                          {matchCount}
                        </span>
                      ) : null}
                    </h6>
                    <AccountResult account={results} onScroll={handleScroll} user={user} beginEdit={beginEdit} />
                    {scrolling ? <Loader /> : null}
                  </div>
                </div>
              </div>
            </>
          ) : ''}
          {count === 0 && response ? <NoRecordFound /> : '' }
        </>
      ) : (
        <Loader />
      )}

      {showAccntReqModal && (
        <>
          <div className="log-out-layer" />
          <CreateAccountRequest
            saveUrl="/api/accountRequest"
            label="Request Account"
            user={user}
            onCancel={onAccntReqModal}
            title="Request an AWS Account"
          />
        </>
      )}

      {showAccntEditModal && (
        <>
          <div className="log-out-layer" />
          <EditAccountRequest
            saveUrl={`/api/account/${id || ''}`}
            saveVerb="PUT"
            showNumber
            label="Save"
            id={id}
            user={user}
            onCancel={cancelEdit}
            doSearch={doSearch}
            title="Edit Account"
            accNum={accNum}
            accName={accName}
          />
        </>
      )}
    </>
  );
};

export default Accounts;
